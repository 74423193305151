import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImgProps, getStaticImgProps } from "helpers/getImgProps";
import * as styles from "./referTop.module.scss";

const ReferTop = ({
  titlePartOne,
  titlePartTwo,
  subtitle,
  image,
  btn,
  btnArrow,
}) => {
  return (
    <div
      className={`${styles.wrapper} d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-center`}
    >
      <div className={styles.textContainer}>
        <p className={`${styles.title} mb-30`}>
          {titlePartOne} <span>{titlePartTwo}</span>
        </p>
        <p className={`${styles.text} mb-50`}>{subtitle}</p>
        <div role="button" tabIndex={0} className={styles.becomePartnerBtn}>
          <AnchorLink to="#schedule">
            {btn}
            {btnArrow.localFile.url && (
              <img
                {...getStaticImgProps(btnArrow)}
                className={styles.btnArrow}
              />
            )}
          </AnchorLink>
        </div>
      </div>
      <div className={styles.imgContainer}>
        <GatsbyImage {...getImgProps(image)} />
      </div>
    </div>
  );
};

export default ReferTop;
