import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import { validate } from "helpers/validateFormToApply";
import { navigate } from "gatsby";
import * as styles from "./formToApply.module.scss";

const FormToApply = () => {
  const [isVerified, setIsVerified] = useState(false);

  const handleReCapthcaOnChange = (value) => {
    setIsVerified(value ? true : false);
  };

  const submitForm = async (formData) => {
    const url = `${process.env.GATSBY_STRAPI_API_URL}/api/partner-info-messages`;
    const data = { data: formData };
    try {
      if (isVerified) {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to create data");
            }
            return response.json();
          })
          .then(() => {
            navigate("/thank-you");
          })
          .catch((error) => {
            console.error("Error creating data:", error);
          });
      }
    } catch (error) {
      console.error("Form submission failed");
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      submitForm(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div
        className={`${styles.inputFields} d-flex flex-column flex-md-row justify-content-md-between align-items-center`}
      >
        <input
          id="fullName"
          name="fullName"
          type="text"
          placeholder="Your Full Name*"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fullName}
          className={
            formik.errors.fullName
              ? `${styles.invalid} ${styles.fullName}`
              : styles.fullName
          }
        />
        <input
          id="email"
          name="email"
          type="email"
          placeholder="Your email*"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className={
            formik.errors.email
              ? `${styles.invalid} ${styles.email}`
              : styles.email
          }
        />
        <input
          id="phone"
          name="phone"
          type="text"
          placeholder="Your phone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          className={styles.phone}
        />
        <input
          id="message"
          name="message"
          type="textarea"
          placeholder="Message*"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          className={
            formik.errors.message
              ? `${styles.invalid} ${styles.message}`
              : styles.message
          }
        />
      </div>
      <div className={styles.recaptcha}>
        <ReCAPTCHA
          sitekey="6LfFrUsmAAAAAMEXJ-Nm9eHFOEEA44zxDohPLoqw"
          onChange={handleReCapthcaOnChange}
        />
      </div>
      <button type="submit" className={styles.btn}>
        Talk to our team
      </button>
    </form>
  );
};

export default FormToApply;
