import React from "react";
import { InlineWidget } from "react-calendly";

const Calendly = () => {
  return (
    <div className="inline-widget">
      <InlineWidget url="https://calendly.com/nare-5" />
    </div>
  );
};

export default Calendly;
