import React from "react";
import { graphql } from "gatsby";
import { getReferPageProps } from "helpers/getReferPageProps";
import Seo from "components/seo";
import ReferTop from "components/referTop";
import Business from "components/business";
import GetStarted from "components/getStarted";
import LetsTalkComponent from "components/letsTalkComponent";
import SchedulePart from "components/schedulePart";
import Layout from "components/layout";

const Refer = ({ data }) => {
  const { seo, referTop, business, getStarted, letsTalk } =
    getReferPageProps(data);

  return (
    <Layout>
      <Seo {...seo} />
      <ReferTop {...referTop} />
      <Business {...business} />
      <GetStarted {...getStarted} />
      <SchedulePart />
      <LetsTalkComponent {...letsTalk} />
    </Layout>
  );
};

export const query = graphql`
  query ReferPageData {
    strapiRefer {
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            url
          }
        }
      }
      referTop {
        titlePartOne
        titlePartTwo
        btn
        btnArrow {
          alternativeText
          localFile {
            url
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      business {
        title
        subtitle
        business {
          title
          text
          order
          image {
            alternativeText
            localFile {
              url
            }
          }
        }
      }
      getStarted {
        title
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      letsTalk {
        title
        subtitle
        btn
        bgDark
        address
        btnArrow {
          alternativeText
          localFile {
            url
          }
        }
        preezmaLogo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default Refer;
