import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./getStarted.module.scss";

const GetStarted = ({ title, text, image }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.getStarted} d-flex flex-md-row flex-column justify-content-md-between align-items-md-center mb-80`}
      >
        <div className={styles.imgWrapper}>
          <GatsbyImage {...getImgProps(image)} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.bg}></div>
          <p className={styles.title}>{title}</p>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
