export const getReferPageProps = ({
  strapiRefer: { seo, referTop, schedulePart, business, getStarted, letsTalk },
}) => {
  return {
    seo,
    referTop,
    schedulePart,
    business,
    getStarted,
    letsTalk,
  };
};
