import React from "react";
import * as styles from "./form.module.scss";
import FormToApply from "./formToApply";

const FormPartnership = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.subtitle}>
        Feel free to fill out the info below and one of our team members will
        get right back to you. Alternatively if you prefer, you can set up a
        time to chat with us via Calendly. We look forward to hearing from you!
      </p>
      <FormToApply />
    </div>
  );
};

export default FormPartnership;
