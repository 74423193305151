import React from "react";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import { getStaticImgProps } from "helpers/getImgProps";
import * as styles from "./business.module.scss";

const Business = ({ title, subtitle, business }) => {
  const businessList = sortArrayOfObjects(
    business.map(({ text, title, image }, index) => {
      return (
        <div key={index} className={styles.benefitWrapper}>
          <div
            className={`${styles.icon} d-flex justify-content-center align-items-center`}
          >
            <img {...getStaticImgProps(image)} />
          </div>
          <p className={`${styles.titleBenefit} mb-20`}>{title}</p>
          <p className={styles.description}>{text}</p>
        </div>
      );
    })
  );
  return (
    <div className={styles.wrapper}>
      <p className={`${styles.title} mb-25`}>{title}</p>
      <p className={`${styles.subtitle} mb-40`}>{subtitle} </p>
      <div
        className={`${styles.benefitList} d-flex justify-content-between align-items-stretch mb-85`}
      >
        {businessList}
      </div>
    </div>
  );
};

export default Business;
