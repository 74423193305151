// extracted by mini-css-extract-plugin
export var benefitList = "business-module--benefitList--3109f";
export var benefitWrapper = "business-module--benefitWrapper--70255";
export var description = "business-module--description--bcf06";
export var icon = "business-module--icon--bcb1c";
export var ma0 = "business-module--ma-0--8df24";
export var ma10 = "business-module--ma-10--576f2";
export var ma100 = "business-module--ma-100--41cf1";
export var ma105 = "business-module--ma-105--cb158";
export var ma110 = "business-module--ma-110--e252a";
export var ma115 = "business-module--ma-115--f47e4";
export var ma12 = "business-module--ma-12--28a31";
export var ma120 = "business-module--ma-120--f2738";
export var ma130 = "business-module--ma-130--5863a";
export var ma140 = "business-module--ma-140--2e9a7";
export var ma15 = "business-module--ma-15--5a458";
export var ma150 = "business-module--ma-150--f90f4";
export var ma16 = "business-module--ma-16--152ea";
export var ma18 = "business-module--ma-18--c547e";
export var ma185 = "business-module--ma-185--475f5";
export var ma20 = "business-module--ma-20--48000";
export var ma25 = "business-module--ma-25--24866";
export var ma30 = "business-module--ma-30--c86da";
export var ma35 = "business-module--ma-35--7a3de";
export var ma40 = "business-module--ma-40--4a372";
export var ma45 = "business-module--ma-45--ca1c7";
export var ma48 = "business-module--ma-48--60b86";
export var ma5 = "business-module--ma-5--7f7c2";
export var ma50 = "business-module--ma-50--37c53";
export var ma55 = "business-module--ma-55--8f6bc";
export var ma60 = "business-module--ma-60--799fb";
export var ma70 = "business-module--ma-70--10bb7";
export var ma80 = "business-module--ma-80--fdb3a";
export var ma85 = "business-module--ma-85--105b1";
export var ma90 = "business-module--ma-90--b5879";
export var ma95 = "business-module--ma-95--8db87";
export var mb0 = "business-module--mb-0--af87a";
export var mb10 = "business-module--mb-10--cda27";
export var mb100 = "business-module--mb-100--5c799";
export var mb105 = "business-module--mb-105--fa622";
export var mb110 = "business-module--mb-110--122cc";
export var mb115 = "business-module--mb-115--41e21";
export var mb12 = "business-module--mb-12--fd34b";
export var mb120 = "business-module--mb-120--7419f";
export var mb130 = "business-module--mb-130--12750";
export var mb140 = "business-module--mb-140--65e45";
export var mb15 = "business-module--mb-15--7baac";
export var mb150 = "business-module--mb-150--d2546";
export var mb16 = "business-module--mb-16--b6e2b";
export var mb18 = "business-module--mb-18--ed9d1";
export var mb185 = "business-module--mb-185--2322c";
export var mb20 = "business-module--mb-20--34f6a";
export var mb25 = "business-module--mb-25--70269";
export var mb30 = "business-module--mb-30--b8734";
export var mb35 = "business-module--mb-35--bdecb";
export var mb40 = "business-module--mb-40--c1a45";
export var mb45 = "business-module--mb-45--e99ba";
export var mb48 = "business-module--mb-48--0c083";
export var mb5 = "business-module--mb-5--01894";
export var mb50 = "business-module--mb-50--2e913";
export var mb55 = "business-module--mb-55--32321";
export var mb60 = "business-module--mb-60--97551";
export var mb70 = "business-module--mb-70--dda27";
export var mb80 = "business-module--mb-80--574f2";
export var mb85 = "business-module--mb-85--ec493";
export var mb90 = "business-module--mb-90--56171";
export var mb95 = "business-module--mb-95--43b80";
export var mh0 = "business-module--mh-0--92089";
export var mh10 = "business-module--mh-10--0bc8f";
export var mh100 = "business-module--mh-100--96b29";
export var mh105 = "business-module--mh-105--27281";
export var mh110 = "business-module--mh-110--447a9";
export var mh115 = "business-module--mh-115--af179";
export var mh12 = "business-module--mh-12--b4a32";
export var mh120 = "business-module--mh-120--f5c97";
export var mh130 = "business-module--mh-130--2f19e";
export var mh140 = "business-module--mh-140--9c210";
export var mh15 = "business-module--mh-15--12471";
export var mh150 = "business-module--mh-150--288bc";
export var mh16 = "business-module--mh-16--693d7";
export var mh18 = "business-module--mh-18--45a06";
export var mh185 = "business-module--mh-185--065d4";
export var mh20 = "business-module--mh-20--11a86";
export var mh25 = "business-module--mh-25--c06a9";
export var mh30 = "business-module--mh-30--49e59";
export var mh35 = "business-module--mh-35--4f492";
export var mh40 = "business-module--mh-40--7de55";
export var mh45 = "business-module--mh-45--ff334";
export var mh48 = "business-module--mh-48--69a23";
export var mh5 = "business-module--mh-5--f714d";
export var mh50 = "business-module--mh-50--31f9a";
export var mh55 = "business-module--mh-55--b89fe";
export var mh60 = "business-module--mh-60--22bad";
export var mh70 = "business-module--mh-70--f1c10";
export var mh80 = "business-module--mh-80--0038e";
export var mh85 = "business-module--mh-85--cc674";
export var mh90 = "business-module--mh-90--ed8ff";
export var mh95 = "business-module--mh-95--0c166";
export var ml0 = "business-module--ml-0--968cb";
export var ml10 = "business-module--ml-10--97e1c";
export var ml100 = "business-module--ml-100--e8cdf";
export var ml105 = "business-module--ml-105--8f46b";
export var ml110 = "business-module--ml-110--0a450";
export var ml115 = "business-module--ml-115--96450";
export var ml12 = "business-module--ml-12--2c3e9";
export var ml120 = "business-module--ml-120--9372f";
export var ml130 = "business-module--ml-130--09a61";
export var ml140 = "business-module--ml-140--491b1";
export var ml15 = "business-module--ml-15--ac8ec";
export var ml150 = "business-module--ml-150--0dfd3";
export var ml16 = "business-module--ml-16--5b4a1";
export var ml18 = "business-module--ml-18--953b9";
export var ml185 = "business-module--ml-185--cd322";
export var ml20 = "business-module--ml-20--3549e";
export var ml25 = "business-module--ml-25--29353";
export var ml30 = "business-module--ml-30--e3de8";
export var ml35 = "business-module--ml-35--b4fab";
export var ml40 = "business-module--ml-40--39f23";
export var ml45 = "business-module--ml-45--b3cbe";
export var ml48 = "business-module--ml-48--53869";
export var ml5 = "business-module--ml-5--6cd48";
export var ml50 = "business-module--ml-50--76c7d";
export var ml55 = "business-module--ml-55--fcb32";
export var ml60 = "business-module--ml-60--d7ada";
export var ml70 = "business-module--ml-70--bb692";
export var ml80 = "business-module--ml-80--4b948";
export var ml85 = "business-module--ml-85--0369f";
export var ml90 = "business-module--ml-90--bbda1";
export var ml95 = "business-module--ml-95--d0cb0";
export var mr0 = "business-module--mr-0--b8c8e";
export var mr10 = "business-module--mr-10--e1bdd";
export var mr100 = "business-module--mr-100--c45a9";
export var mr105 = "business-module--mr-105--9949f";
export var mr110 = "business-module--mr-110--04fda";
export var mr115 = "business-module--mr-115--9b293";
export var mr12 = "business-module--mr-12--b875c";
export var mr120 = "business-module--mr-120--6d9c6";
export var mr130 = "business-module--mr-130--b9c88";
export var mr140 = "business-module--mr-140--b672f";
export var mr15 = "business-module--mr-15--59357";
export var mr150 = "business-module--mr-150--a602a";
export var mr16 = "business-module--mr-16--d44d4";
export var mr18 = "business-module--mr-18--81e22";
export var mr185 = "business-module--mr-185--9a3c0";
export var mr20 = "business-module--mr-20--3c9f2";
export var mr25 = "business-module--mr-25--df9cc";
export var mr30 = "business-module--mr-30--5ffae";
export var mr35 = "business-module--mr-35--b5e49";
export var mr40 = "business-module--mr-40--35858";
export var mr45 = "business-module--mr-45--530d4";
export var mr48 = "business-module--mr-48--1d4f9";
export var mr5 = "business-module--mr-5--5be4f";
export var mr50 = "business-module--mr-50--ed815";
export var mr55 = "business-module--mr-55--c2103";
export var mr60 = "business-module--mr-60--981ce";
export var mr70 = "business-module--mr-70--a437e";
export var mr80 = "business-module--mr-80--ce245";
export var mr85 = "business-module--mr-85--f941b";
export var mr90 = "business-module--mr-90--429e9";
export var mr95 = "business-module--mr-95--181c7";
export var mt0 = "business-module--mt-0--3bcab";
export var mt10 = "business-module--mt-10--b2e84";
export var mt100 = "business-module--mt-100--cd892";
export var mt105 = "business-module--mt-105--fb2f5";
export var mt110 = "business-module--mt-110--035f7";
export var mt115 = "business-module--mt-115--43e74";
export var mt12 = "business-module--mt-12--0f0e0";
export var mt120 = "business-module--mt-120--d86af";
export var mt130 = "business-module--mt-130--64d60";
export var mt140 = "business-module--mt-140--beef5";
export var mt15 = "business-module--mt-15--d631a";
export var mt150 = "business-module--mt-150--61707";
export var mt16 = "business-module--mt-16--c132e";
export var mt18 = "business-module--mt-18--71627";
export var mt185 = "business-module--mt-185--af4d7";
export var mt20 = "business-module--mt-20--11397";
export var mt25 = "business-module--mt-25--68fcf";
export var mt30 = "business-module--mt-30--27839";
export var mt35 = "business-module--mt-35--7e556";
export var mt40 = "business-module--mt-40--1c53b";
export var mt45 = "business-module--mt-45--ddb9b";
export var mt48 = "business-module--mt-48--cf9aa";
export var mt5 = "business-module--mt-5--c219d";
export var mt50 = "business-module--mt-50--79be3";
export var mt55 = "business-module--mt-55--ffbdd";
export var mt60 = "business-module--mt-60--8b657";
export var mt70 = "business-module--mt-70--f6a04";
export var mt80 = "business-module--mt-80--edcc4";
export var mt85 = "business-module--mt-85--42777";
export var mt90 = "business-module--mt-90--a69d1";
export var mt95 = "business-module--mt-95--a9c5d";
export var mv0 = "business-module--mv-0--26fa8";
export var mv10 = "business-module--mv-10--7d46c";
export var mv100 = "business-module--mv-100--4d2e0";
export var mv105 = "business-module--mv-105--87046";
export var mv110 = "business-module--mv-110--e6227";
export var mv115 = "business-module--mv-115--2a2e6";
export var mv12 = "business-module--mv-12--70a25";
export var mv120 = "business-module--mv-120--b8046";
export var mv130 = "business-module--mv-130--2aff0";
export var mv140 = "business-module--mv-140--289c3";
export var mv15 = "business-module--mv-15--2f4d8";
export var mv150 = "business-module--mv-150--ac170";
export var mv16 = "business-module--mv-16--42d62";
export var mv18 = "business-module--mv-18--c06d7";
export var mv185 = "business-module--mv-185--03905";
export var mv20 = "business-module--mv-20--19338";
export var mv25 = "business-module--mv-25--17b21";
export var mv30 = "business-module--mv-30--8a1ae";
export var mv35 = "business-module--mv-35--8e912";
export var mv40 = "business-module--mv-40--71c91";
export var mv45 = "business-module--mv-45--bae34";
export var mv48 = "business-module--mv-48--0cce2";
export var mv5 = "business-module--mv-5--412a3";
export var mv50 = "business-module--mv-50--ebb26";
export var mv55 = "business-module--mv-55--f78cb";
export var mv60 = "business-module--mv-60--58d64";
export var mv70 = "business-module--mv-70--9f665";
export var mv80 = "business-module--mv-80--031f4";
export var mv85 = "business-module--mv-85--95fa9";
export var mv90 = "business-module--mv-90--ed244";
export var mv95 = "business-module--mv-95--e46e0";
export var pa0 = "business-module--pa-0--797f8";
export var pa10 = "business-module--pa-10--3dd43";
export var pa100 = "business-module--pa-100--ac7f7";
export var pa105 = "business-module--pa-105--a078b";
export var pa110 = "business-module--pa-110--f1715";
export var pa115 = "business-module--pa-115--aca37";
export var pa12 = "business-module--pa-12--5cca7";
export var pa120 = "business-module--pa-120--3c105";
export var pa130 = "business-module--pa-130--4aa5a";
export var pa140 = "business-module--pa-140--ebdec";
export var pa15 = "business-module--pa-15--29ada";
export var pa150 = "business-module--pa-150--385f5";
export var pa16 = "business-module--pa-16--4cc95";
export var pa18 = "business-module--pa-18--08cb4";
export var pa185 = "business-module--pa-185--c3881";
export var pa20 = "business-module--pa-20--57abc";
export var pa25 = "business-module--pa-25--7ddaf";
export var pa30 = "business-module--pa-30--6f76d";
export var pa35 = "business-module--pa-35--31869";
export var pa40 = "business-module--pa-40--efd49";
export var pa45 = "business-module--pa-45--02ac6";
export var pa48 = "business-module--pa-48--0fbf2";
export var pa5 = "business-module--pa-5--a9842";
export var pa50 = "business-module--pa-50--fa5df";
export var pa55 = "business-module--pa-55--52b60";
export var pa60 = "business-module--pa-60--37a9e";
export var pa70 = "business-module--pa-70--92d2e";
export var pa80 = "business-module--pa-80--5c23d";
export var pa85 = "business-module--pa-85--c94ad";
export var pa90 = "business-module--pa-90--4f6a4";
export var pa95 = "business-module--pa-95--a2cf8";
export var pb0 = "business-module--pb-0--1c944";
export var pb10 = "business-module--pb-10--7a714";
export var pb100 = "business-module--pb-100--04058";
export var pb105 = "business-module--pb-105--e11a9";
export var pb110 = "business-module--pb-110--2993f";
export var pb115 = "business-module--pb-115--a12ca";
export var pb12 = "business-module--pb-12--f61d0";
export var pb120 = "business-module--pb-120--eee02";
export var pb130 = "business-module--pb-130--6270b";
export var pb140 = "business-module--pb-140--ee32b";
export var pb15 = "business-module--pb-15--80dce";
export var pb150 = "business-module--pb-150--a24cf";
export var pb16 = "business-module--pb-16--b293f";
export var pb18 = "business-module--pb-18--d9472";
export var pb185 = "business-module--pb-185--f6400";
export var pb20 = "business-module--pb-20--ddc89";
export var pb25 = "business-module--pb-25--c2015";
export var pb30 = "business-module--pb-30--625ec";
export var pb35 = "business-module--pb-35--056a7";
export var pb40 = "business-module--pb-40--18ece";
export var pb45 = "business-module--pb-45--9ace9";
export var pb48 = "business-module--pb-48--c747f";
export var pb5 = "business-module--pb-5--81278";
export var pb50 = "business-module--pb-50--4db05";
export var pb55 = "business-module--pb-55--10403";
export var pb60 = "business-module--pb-60--5ab92";
export var pb70 = "business-module--pb-70--5359c";
export var pb80 = "business-module--pb-80--fe1eb";
export var pb85 = "business-module--pb-85--1de0a";
export var pb90 = "business-module--pb-90--d8a4e";
export var pb95 = "business-module--pb-95--8c2e1";
export var ph0 = "business-module--ph-0--14224";
export var ph10 = "business-module--ph-10--6d6d9";
export var ph100 = "business-module--ph-100--3ccee";
export var ph105 = "business-module--ph-105--d0701";
export var ph110 = "business-module--ph-110--382de";
export var ph115 = "business-module--ph-115--d0c02";
export var ph12 = "business-module--ph-12--9ab6e";
export var ph120 = "business-module--ph-120--d2e19";
export var ph130 = "business-module--ph-130--e1101";
export var ph140 = "business-module--ph-140--1a9d4";
export var ph15 = "business-module--ph-15--45e1d";
export var ph150 = "business-module--ph-150--ebbe1";
export var ph16 = "business-module--ph-16--b2fe2";
export var ph18 = "business-module--ph-18--00d29";
export var ph185 = "business-module--ph-185--4f71b";
export var ph20 = "business-module--ph-20--4f2a1";
export var ph25 = "business-module--ph-25--b82f8";
export var ph30 = "business-module--ph-30--04148";
export var ph35 = "business-module--ph-35--f6c4f";
export var ph40 = "business-module--ph-40--680c6";
export var ph45 = "business-module--ph-45--80132";
export var ph48 = "business-module--ph-48--09134";
export var ph5 = "business-module--ph-5--fe555";
export var ph50 = "business-module--ph-50--1301a";
export var ph55 = "business-module--ph-55--90308";
export var ph60 = "business-module--ph-60--a2fe8";
export var ph70 = "business-module--ph-70--b3512";
export var ph80 = "business-module--ph-80--3714b";
export var ph85 = "business-module--ph-85--0ac98";
export var ph90 = "business-module--ph-90--50c61";
export var ph95 = "business-module--ph-95--2b428";
export var pl0 = "business-module--pl-0--222f0";
export var pl10 = "business-module--pl-10--ccb7c";
export var pl100 = "business-module--pl-100--191da";
export var pl105 = "business-module--pl-105--04371";
export var pl110 = "business-module--pl-110--dc21e";
export var pl115 = "business-module--pl-115--df74f";
export var pl12 = "business-module--pl-12--cd8d8";
export var pl120 = "business-module--pl-120--7786a";
export var pl130 = "business-module--pl-130--09c7e";
export var pl140 = "business-module--pl-140--f1447";
export var pl15 = "business-module--pl-15--b4159";
export var pl150 = "business-module--pl-150--90de5";
export var pl16 = "business-module--pl-16--753bd";
export var pl18 = "business-module--pl-18--d34df";
export var pl185 = "business-module--pl-185--3f327";
export var pl20 = "business-module--pl-20--a6184";
export var pl25 = "business-module--pl-25--862c6";
export var pl30 = "business-module--pl-30--0825b";
export var pl35 = "business-module--pl-35--7ae8a";
export var pl40 = "business-module--pl-40--363b0";
export var pl45 = "business-module--pl-45--87ad5";
export var pl48 = "business-module--pl-48--5d475";
export var pl5 = "business-module--pl-5--b5699";
export var pl50 = "business-module--pl-50--5a05f";
export var pl55 = "business-module--pl-55--a1ed6";
export var pl60 = "business-module--pl-60--2e48e";
export var pl70 = "business-module--pl-70--d7380";
export var pl80 = "business-module--pl-80--553d6";
export var pl85 = "business-module--pl-85--63338";
export var pl90 = "business-module--pl-90--855b7";
export var pl95 = "business-module--pl-95--02baf";
export var pr0 = "business-module--pr-0--2ad19";
export var pr10 = "business-module--pr-10--623f9";
export var pr100 = "business-module--pr-100--1e8a7";
export var pr105 = "business-module--pr-105--4e12b";
export var pr110 = "business-module--pr-110--414ea";
export var pr115 = "business-module--pr-115--6ee79";
export var pr12 = "business-module--pr-12--51dd9";
export var pr120 = "business-module--pr-120--18edb";
export var pr130 = "business-module--pr-130--dd961";
export var pr140 = "business-module--pr-140--7e27a";
export var pr15 = "business-module--pr-15--6bbff";
export var pr150 = "business-module--pr-150--77ffe";
export var pr16 = "business-module--pr-16--40f60";
export var pr18 = "business-module--pr-18--b0549";
export var pr185 = "business-module--pr-185--6c57a";
export var pr20 = "business-module--pr-20--c985f";
export var pr25 = "business-module--pr-25--12577";
export var pr30 = "business-module--pr-30--2cb8b";
export var pr35 = "business-module--pr-35--870f6";
export var pr40 = "business-module--pr-40--11981";
export var pr45 = "business-module--pr-45--59721";
export var pr48 = "business-module--pr-48--6b598";
export var pr5 = "business-module--pr-5--64b6d";
export var pr50 = "business-module--pr-50--462cc";
export var pr55 = "business-module--pr-55--27d5f";
export var pr60 = "business-module--pr-60--2a446";
export var pr70 = "business-module--pr-70--26288";
export var pr80 = "business-module--pr-80--4f5b2";
export var pr85 = "business-module--pr-85--3781a";
export var pr90 = "business-module--pr-90--813bc";
export var pr95 = "business-module--pr-95--5f1f6";
export var pt0 = "business-module--pt-0--e61d7";
export var pt10 = "business-module--pt-10--75554";
export var pt100 = "business-module--pt-100--5b031";
export var pt105 = "business-module--pt-105--94fb9";
export var pt110 = "business-module--pt-110--1d3dd";
export var pt115 = "business-module--pt-115--6576a";
export var pt12 = "business-module--pt-12--8be06";
export var pt120 = "business-module--pt-120--afe39";
export var pt130 = "business-module--pt-130--460b7";
export var pt140 = "business-module--pt-140--50819";
export var pt15 = "business-module--pt-15--b4cd2";
export var pt150 = "business-module--pt-150--0ac3e";
export var pt16 = "business-module--pt-16--b15e1";
export var pt18 = "business-module--pt-18--bb583";
export var pt185 = "business-module--pt-185--4d490";
export var pt20 = "business-module--pt-20--b6d3e";
export var pt25 = "business-module--pt-25--3ed27";
export var pt30 = "business-module--pt-30--99279";
export var pt35 = "business-module--pt-35--3bdc3";
export var pt40 = "business-module--pt-40--bea82";
export var pt45 = "business-module--pt-45--d7faf";
export var pt48 = "business-module--pt-48--4bfce";
export var pt5 = "business-module--pt-5--a38d0";
export var pt50 = "business-module--pt-50--a1771";
export var pt55 = "business-module--pt-55--fec67";
export var pt60 = "business-module--pt-60--b074f";
export var pt70 = "business-module--pt-70--88c81";
export var pt80 = "business-module--pt-80--178db";
export var pt85 = "business-module--pt-85--4e286";
export var pt90 = "business-module--pt-90--22ee3";
export var pt95 = "business-module--pt-95--b898b";
export var pv0 = "business-module--pv-0--ffdc6";
export var pv10 = "business-module--pv-10--31493";
export var pv100 = "business-module--pv-100--d4462";
export var pv105 = "business-module--pv-105--d941b";
export var pv110 = "business-module--pv-110--fb0cb";
export var pv115 = "business-module--pv-115--48239";
export var pv12 = "business-module--pv-12--30e68";
export var pv120 = "business-module--pv-120--f30a5";
export var pv130 = "business-module--pv-130--6bbd5";
export var pv140 = "business-module--pv-140--c2665";
export var pv15 = "business-module--pv-15--d182c";
export var pv150 = "business-module--pv-150--e4e6b";
export var pv16 = "business-module--pv-16--0fb1d";
export var pv18 = "business-module--pv-18--a14b7";
export var pv185 = "business-module--pv-185--f0f93";
export var pv20 = "business-module--pv-20--096ca";
export var pv25 = "business-module--pv-25--668f7";
export var pv30 = "business-module--pv-30--798ea";
export var pv35 = "business-module--pv-35--643ce";
export var pv40 = "business-module--pv-40--45375";
export var pv45 = "business-module--pv-45--d1540";
export var pv48 = "business-module--pv-48--9445e";
export var pv5 = "business-module--pv-5--37f2f";
export var pv50 = "business-module--pv-50--c65bb";
export var pv55 = "business-module--pv-55--513d8";
export var pv60 = "business-module--pv-60--7e453";
export var pv70 = "business-module--pv-70--c680c";
export var pv80 = "business-module--pv-80--43a91";
export var pv85 = "business-module--pv-85--4d6f1";
export var pv90 = "business-module--pv-90--dd4f2";
export var pv95 = "business-module--pv-95--67758";
export var subtitle = "business-module--subtitle--e40f6";
export var title = "business-module--title--4e8bb";
export var titleBenefit = "business-module--titleBenefit--eb7e7";
export var wrapper = "business-module--wrapper--ea4cc";