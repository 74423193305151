import React from "react";
import Calendly from "components/calendly";
import FormPartnership from "components/form";
import * as styles from "./schedule.module.scss";

const SchedulePart = () => {
  return (
    <div className={styles.wrapper} id="schedule">
      <div className={styles.calendly}>
        <Calendly />
      </div>
      <div className={styles.form}>
        <FormPartnership />
      </div>
    </div>
  );
};

export default SchedulePart;
